import axiosInstance from "../../mixins/axios";

export const getFooter = () => {
  return async () => {
    try {
      const response = await axiosInstance.get('/footer/');
      return response.data;
    } catch (error) {
      return error.response;
    }
  };
};
