import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as authActions from "../../store/actions/authActions";
import * as newsletterActions from "../../store/actions/newsletterActions";
import InexploreLogo from "../../assets/images/svg/inexplore-logo.svg";
import InreesLogo from "../../assets/images/svg/inrees-logo.svg";
import MyinexploreLogo from "../../assets/images/svg/my-inexplore-logo.svg";
import InexploreTVLogo from "../../assets/images/svg/inexplore-tv-logo.svg";
import { ReactComponent as ArrowLeft } from "../../assets/images/svg/arrow-left.svg";

export default function AuthHeader(props) {
  const authReducer = useSelector((state) => state.authReducer);
  const newsletterReducer = useSelector((state) => state.newsletterReducer);
  const [pathName, setPathName] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const handleForgotPasswordFormSubmited = (value) => {
    props.handleForgotPasswordFormSubmitedParent(value);
  };

  const goBack = (step) => {
    dispatch(authActions.updateStep(step));
    navigate("/");
    handleForgotPasswordFormSubmited(false);
  };

  const goBackFromNewsletterRegistrationForm = () => {
    dispatch(newsletterActions.removeNewsletterEmailCode());
    if (props.referrer === null) {
      navigate("/");
    } else {
      window.location.href = props.referrer;
    }
  };

  const renderBackButton = () => {
    switch (pathName) {
      case "/forgot-password":
        return (
          <button
            onClick={() => goBack(!props.forgotPasswordFormSubmited ? 1 : 0)}
          >
            <ArrowLeft />
          </button>
        );

      case "/register":
        return (
          <button onClick={() => dispatch(authActions.removeEmailCode())}>
            <ArrowLeft />
          </button>
        );
      case "/newsletter":
        if (!newsletterReducer.preferencesUpdated) {
          return (
            <button onClick={() => goBackFromNewsletterRegistrationForm()}>
              <ArrowLeft />
            </button>
          );
        }
      case "/":
        return (
          authReducer.step !== 0 &&
          !authReducer.connected && (
            <button onClick={() => dispatch(authActions.updateStep(0))}>
              <ArrowLeft />
            </button>
          )
        );
    }
  };

  return (
    <header>
      <div
        className={
          props.matchCode
            ? "container last-step"
            : "container container-full-width"
        }
      >
        {renderBackButton()}
        {props.matchCode && (
          <img
            src={MyinexploreLogo}
            className="logo-my-inexplore"
            alt="logo My Inexploré"
          ></img>
        )}
        <a href="https://www.inrees.com/" target="_blank" rel="noreferrer">
          <img src={InreesLogo} className="logo-inrees" alt="logo Inrees"></img>
        </a>
        <a href="https://www.inexplore.com/" target="_blank" rel="noreferrer">
          <img
            src={InexploreLogo}
            className="logo-inexplore"
            alt="Inexploré"
          ></img>
        </a>
        <a href="https://tv.inexplore.com/" target="_blank" rel="noreferrer">
          <img
            src={InexploreTVLogo}
            className="logo-inexploreTV"
            alt="Inexploré TV"
          ></img>
        </a>
      </div>
    </header>
  );
}
