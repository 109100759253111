import {
  SET_USER_EMAIL_NEWSLETTER_CODE,
  SET_USER_EMAIL_NEWSLETTER_PREFERENCES_UPDATED,
  RESET_USER_EMAIL_NEWSLETTER_CODE,
} from "../actions/newsletterActions";

const initialState = {
  email: "",
  code: null,
  preferencesUpdated: false,
};

function newsletterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_EMAIL_NEWSLETTER_CODE:
      return {
        ...state,
        email: action.payload.email,
        code: action.payload.code,
      };
    case SET_USER_EMAIL_NEWSLETTER_PREFERENCES_UPDATED:
      return {
        ...state,
        preferencesUpdated: true,
      };
    case RESET_USER_EMAIL_NEWSLETTER_CODE:
      return initialState;
    default:
      return state;
  }
}

export default newsletterReducer;
