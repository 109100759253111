import React, { useState } from "react";
import countryCodes from "../../../constants/countryCodes";
import { ReactComponent as Smartphone } from "../../../assets/images/svg/smartphone.svg";

export default function PhoneInput(props) {
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    setOpen(false);
    props.handleChangeParent(e.target.value);
  };

  const handleChangeCountryCode = (value) => {
    props.handleChangeCountryCodeParent(value);
    handleOpen();
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="phone-input">
      <div className={props.className}>
        <div onClick={handleOpen} className="country-code-selector">
          {props.countryCode.flag}
        </div>
        <div className="input-container">
          <input
            type={props.type}
            value={props.value}
            disabled={props.disabled}
            onChange={handleChange}
          />
          <label className={props.value && "filled"}>{props.label}</label>
        </div>
        {props.requiredMention ? (
          props.rightIcon ? (
            <div className="required-right-icon">
              <span>requis</span>
              <i>
                <Smartphone />
              </i>
            </div>
          ) : (
            <div className="required-only">
              <span>requis</span>
            </div>
          )
        ) : (
          props.rightIcon && (
            <i>
              <Smartphone />
            </i>
          )
        )}
      </div>
      {open && (
        <div className="country-code-list">
          {countryCodes.map((items) => {
            return (
              <div
                className={
                  props.countryCode.id === items.id
                    ? "country-code-option selected"
                    : "country-code-option "
                }
                onClick={() => handleChangeCountryCode(items)}
              >
                {items.flag} {items.name} ({items.dialcode})
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
