import {
  SET_STEP,
  SET_USER_EMAIL,
  SET_USER_EMAIL_CODE,
  SET_USER_REFERRER,
  SET_USER_CONNECTED,
  SET_USER_LOGGED_OUT,
} from "../actions/authActions";

const initialState = {
  step: 0,
  email: "",
  code: null,
  referrer: null,
  connected: false,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.payload };
    case SET_USER_EMAIL:
      return { ...state, email: action.payload };
    case SET_USER_EMAIL_CODE:
      return { ...state, code: action.payload };
    case SET_USER_REFERRER:
      return { ...state, referrer: action.payload };
    case SET_USER_CONNECTED:
      return { ...state, connected: action.payload };
    case SET_USER_LOGGED_OUT:
      return initialState;
    default:
      return state;
  }
}

export default authReducer;
