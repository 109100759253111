import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../store/actions/authActions";
import * as newsletterActions from "../store/actions/newsletterActions";
import SEOMixins from "../components/mixins/SEOMixins";
import AuthHeader from "../components/layout/AuthHeader";
import AuthForm from "../components/elements/forms/AuthForm";
import AuthWrapper from "../components/elements/AuthWrapper";
import Cookies from "universal-cookie";

export default function Login(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const userEmail = searchParams.get("email");
  const referrer = searchParams.get("referrer");
  const authReducer = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.connected) {
      const cookies = new Cookies();
      const inexploreToken = cookies.get("INEXPLORE_TOKEN");
      cookies.set("INEXPLORE_TOKEN", inexploreToken, {
        path: "/",
        domain: ".inexplore.com",
        expires: new Date(Date.now() + 2678400000),
      });
      const inexploreID = cookies.get("INEXPLORE_ID");
      cookies.set("INEXPLORE_ID", inexploreID, {
        path: "/",
        domain: ".inexplore.com",
        expires: new Date(Date.now() + 2678400000),
      });
      if (referrer !== null) {
        window.location.href = referrer;
      } else {
        if (authReducer.referrer !== null) {
          window.location.href = authReducer.referrer;
        }
      }
      dispatch(newsletterActions.removeNewsletterEmailCode());
    } else {
      if (referrer !== null) {
        dispatch(authActions.updateReferrer(referrer));
      }
      dispatch(newsletterActions.removeNewsletterEmailCode());
    }
  }, [referrer, props.connected, authReducer.referrer]);

  return (
    <div className="main main-auth">
      <SEOMixins title="Inexploré - Connexion" description="Inexploré - Connexion" />
      <div className="login-wrapper">
        <AuthHeader />
        <div className="login-container">
          {!props.connected ? (
            <AuthForm userEmail={!userEmail ? authReducer.email : userEmail} />
          ) : (
            <AuthWrapper
              referrer={referrer !== null ? referrer : authReducer.referrer}
            />
          )}
        </div>
      </div>
    </div>
  );
}
