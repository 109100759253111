import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as EmailValidator from "email-validator";
import * as authActions from "../../../store/actions/authActions";
import Errors from "../../layout/Errors";
import TitleBlock from "../TitleBlock";
import TextInput from "./TextInput";
import Loader from "../loader";
import MyinexploreLogo from "../../../assets/images/svg/my-inexplore-logo.svg";

export default function ForgotPasswordForm(props) {
  const authReducer = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(authReducer.email);
  const [error, setError] = useState({
    status: false,
    message: "",
  });

  const dispatch = useDispatch();

  const handleChangeEmail = (value) => {
    setEmail(value);
    setError({ status: false, message: "" });
  };

  const onSubmitForgotPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (email === "") {
      setError({
        status: true,
        message: "Veuillez renseigner votre adresse email",
      });
    } else {
      const isEmailValid = EmailValidator.validate(email);
      if (!isEmailValid) {
        setError({
          status: true,
          message: "Veuillez renseigner une adresse email valide",
        });
      } else {
        setLoading(true);
        resetPassword();
      }
    }
  };

  const handleForgotPasswordFormSubmited = (value) => {
    props.handleForgotPasswordFormSubmitedParent(value);
  };

  const returnToLogin = () => {
    dispatch(authActions.updateStep(0));
    handleForgotPasswordFormSubmited(false);
  };

  const resetPassword = async () => {
    try {
      const response = await dispatch(
        authActions.resetPassword({ email: email })
      );
      if (response.infos.status === "success") {
        handleForgotPasswordFormSubmited(true);
        setLoading(false);
      } else {
        setError({
          status: true,
          message:
            "Une erreur est survenue. Veuillez réessayer ultérieurement.",
        });
        setLoading(false);
      }
    } catch (error) {
      setError({
        status: true,
        message: "Une erreur est survenue. Veuillez réessayer ultérieurement.",
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <TitleBlock
        logo={true}
        source={MyinexploreLogo}
        h1={
          !props.forgotPasswordFormSubmited
            ? "Réinitialisation de votre mot de passe"
            : "C'est envoyé"
        }
        h2={
          !props.forgotPasswordFormSubmited
            ? "Vous avez oublié votre mot de passe, nous pouvons vous envoyer un email de réinitialisation."
            : "Merci de consulter votre boite mail, nous venons de vous envoyer un lien pour la réinitialisation de votre mot de passe."
        }
      />
      {error.status && (
        <Errors
          className="error-authform"
          backgroundColor={"rgba(233, 75, 50, 0.08)"}
          textColor={"#e94b32"}
          message={error.message}
        />
      )}
      {!props.forgotPasswordFormSubmited && (
        <form
          className="form-container"
          onSubmit={(e) => onSubmitForgotPassword(e)}
        >
          <TextInput
            className={
              !error.status
                ? "input-floating input-border"
                : "input-floating input-border-error"
            }
            label="Mon adresse email"
            type="email"
            value={email}
            rightIcon={true}
            disabled={true}
            requiredMention={false}
            handleChangeParent={handleChangeEmail}
          ></TextInput>
          {loading ? (
            <div className="loader submit-login">
              <Loader size={18} color="#162a41" />
            </div>
          ) : (
            <button className="btn-gradient submit-login" type="submit">
              <span>Reinitialiser mon mot de passe</span>
            </button>
          )}
        </form>
      )}
      {props.forgotPasswordFormSubmited && (
        <button
          className="btn-gradient submit-login full-width"
          type="button"
          onClick={() => returnToLogin()}
        >
          <span>Retour à la page d'accueil</span>
        </button>
      )}
    </div>
  );
}
