import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SEOMixins from "../components/mixins/SEOMixins";
import AuthHeader from "../components/layout/AuthHeader";
import ForgotPasswordForm from "../components/elements/forms/ForgotPasswordForm";

export default function ForgotPassword(props) {
  const authReducer = useSelector((state) => state.authReducer);
  const [formSubmited, setFormSubmited] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (authReducer.step === 0 ) {
      navigate("/");
    }
  }, [navigate, authReducer]);

  const handleFormSubmited = (value) => {
    setFormSubmited(value);
  };

  return (
    <div className="main main-auth">
      <SEOMixins
        title="Inexploré - Mot de passe oublié"
        description="Inexploré - Mot de passe oublié"
      />
      <div className="login-wrapper">
        <AuthHeader
          handleForgotPasswordFormSubmitedParent={handleFormSubmited}
          forgotPasswordFormSubmited={formSubmited}
        />
        <div className="login-container">
          <ForgotPasswordForm
            handleForgotPasswordFormSubmitedParent={handleFormSubmited}
            forgotPasswordFormSubmited={formSubmited}
          />
        </div>
      </div>
    </div>
  );
}
