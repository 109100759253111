import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../../store/actions/authActions";
import * as newsletterActions from "../../../store/actions/newsletterActions";
import TitleBlock from "../TitleBlock";
import Errors from "../../layout/Errors";
import Success from "../../layout/Success";
import Loader from "../loader";
import ReactCodeInput from "react-code-input";
import MyinexploreLogo from "../../../assets/images/svg/my-inexplore-logo.svg";

export default function VerificationEmailCode(props) {
  const authReducer = useSelector((state) => state.authReducer);
  const newsletterReducer = useSelector((state) => state.newsletterReducer);
  const [errorCode, setErrorCode] = useState(false);
  const [message, setMessage] = useState({ status: null, message: "" });
  const [matchCode, setMatchCode] = useState(false);

  const dispatch = useDispatch();

  const handleCodeChange = (value) => {
    props.handleChangeCodeParent(value);
    if (
      value.length === 4 &&
      value !==
        (props.workflow === "register"
          ? authReducer.code
          : newsletterReducer.code)
    ) {
      setErrorCode(true);
    } else {
      setErrorCode(false);
      if (
        value ===
        (props.workflow === "register"
          ? authReducer.code
          : newsletterReducer.code)
      ) {
        setMatchCode(true);
      }
    }
  };


  const generateNewCode = async () => {
    try {
      if (props.workflow === "register") {
        const response = await dispatch(
          authActions.generateEmailCode(authReducer.email)
        );
        if (response.data) {
          setMessage({
            status: "success",
            message:
              "Un nouveau code vous a été envoyé sur votre adresse email",
          });
          removeMessage();
        }
      } else {
        const response = await dispatch(
          newsletterActions.generateEmailCode(newsletterReducer.email)
        );
        if (response.data) {
          setMessage({
            status: "success",
            message:
              "Un nouveau code vous a été envoyé sur votre adresse email",
          });
          removeMessage();
        }
      }
    } catch (error) {
      setMessage({
        status: "error",
        message: "Une erreur est survenue. Veuillez réessayer ultérieurement.",
      });
      removeMessage();
    }
  };

  const removeMessage = () => {
    setTimeout(() => {
      setMessage({
        status: null,
        message: "",
      });
    }, 2000);
  };

  return (
    <div>
      <TitleBlock
        logo={true}
        source={MyinexploreLogo}
        h1="Vérification de votre adresse email"
        h2="Un email contenant un code vous a été envoyé sur l'adresse email. Veuillez saisir le code dans le champ ci-dessous."
      />
      <form className="form-container verification-code">
        <ReactCodeInput
          type="number"
          fields={4}
          className={
            errorCode
              ? "code-input-error"
              : matchCode
              ? "code-input-success"
              : "code-input"
          }
          onChange={handleCodeChange}
        />
      </form>
      {matchCode ? (
        <div className="loader">
          <Loader size={15} color="#162a41" />
        </div>
      ) : message.status === null ? (
        <button
          type="button"
          className="btn-clear btn-clear-dark-blue full-width"
          onClick={() => generateNewCode()}
        >
          Renvoyer un code par email
        </button>
      ) : message.status === "error" ? (
        <Errors
          className="error-authform"
          backgroundColor={"rgba(233, 75, 50, 0.08)"}
          textColor={"#e94b32"}
          message={message.message}
        />
      ) : (
        <Success message={message.message} />
      )}
    </div>
  );
}
