import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import newsletterReducer from "./reducers/newsletter";

export const store = configureStore({
  reducer: {
    authReducer: authReducer,
    newsletterReducer: newsletterReducer,
  },
});

export default store;
