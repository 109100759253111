import React from "react";
import SEOMixins from "../mixins/SEOMixins";
import AuthHeader from "../layout/AuthHeader";
import TitleBlock from "./TitleBlock";
import { ReactComponent as Check } from "../../assets/images/svg/check.svg";
import Loader from "./loader";

export default function NewsletterPreferencesSaved(props) {
  return (
    <div className="main main-auth">
      <SEOMixins
        title="Inexploré - Inscription à la newsletter"
        description="Mise à jour de vos préférences newsletter"
      />
      <div className="login-wrapper">
        <AuthHeader />
        <div className="login-container">
          <div className="auth-wrapper">
            <Check className="icon" fill="#27ae5f" />
            <TitleBlock
              logo={false}
              h1="Vos informations ont été mises à jour."
              h2="Merci de patienter quelques instants, vous allez être redirigé."
            />
            <div className="loader">
              <Loader size={18} color="#162a41" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
