import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as footerActions from "../../store/actions/footerActions";
import * as EmailValidator from "email-validator";
import * as authActions from "../../store/actions/authActions";
import * as newsletterActions from "../../store/actions/newsletterActions";
import ContactFooter from "../elements/contactFooter";
import Loader from "../elements/loader";
import Errors from "./Errors";
import InexploreWhiteLogo from "../../assets/images/svg/inexplore-logo-white.svg";
import InreesWhiteLogo from "../../assets/images/svg/logo-inrees-white.svg";
import { ReactComponent as Send } from "../../assets/images/svg/send.svg";

export default function Footer(props) {
  const [footer, setFooter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    status: false,
    message: "",
  });

  const now = moment().format("YYYY");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function getFooter() {
      const response = await dispatch(footerActions.getFooter());
      setFooter(response.footer);
    }
    getFooter();
  }, []);

  const handleChangeEmail = (value) => {
    setError({ status: false });
    setEmail(value);
  };

  const onNewsletterFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (email === "") {
      setError({
        status: true,
        message: "Veuillez renseigner votre adresse email",
      });
    } else {
      const isEmailValid = EmailValidator.validate(email);
      if (!isEmailValid) {
        setError({
          status: true,
          message: "Veuillez renseigner une adresse email valide",
        });
      } else {
        setLoading(true);
        generateVerificationCode(email);
        setEmail("");
      }
    }
  };

  const generateVerificationCode = async (email) => {
    try {
      const response = await dispatch(
        newsletterActions.generateEmailCode(email)
      );
      if (response.data) {
        navigate("/newsletter");
        setLoading(false);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setError({
        status: true,
        message: "Une erreur est survenue. Veuillez réessayer ultérieurement",
      });
    }
  };

  return (
    <footer>
      <div className="informations">
        <div className="inexplore-logo">
          <a href="https://inexplore.com/" target="_blank" rel="noreferrer">
            <img
              src={InexploreWhiteLogo}
              className="logo"
              alt="logo Inexploré"
            />
          </a>
          <p>À la croisée des mondes</p>
        </div>
        {footer !== null && (
          <div className="socials">
            <div className="social-links">
              <p>{footer.reseaux.titre}</p>
              <ul>
                {footer.reseaux.items.map((item) => {
                  return (
                    <li key={item.titre}>
                      <a href={item.lien} target="_blank">
                        <img
                          src={item.picto}
                          className="icon"
                          alt={item.titre}
                        ></img>
                        {item.titre}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="newsletter">
              <p>Inscrivez-vous à notre newsletter</p>
              <form
                className={
                  !error.status
                    ? "form"
                    : "form input-border-error-newsletter-footer"
                }
              >
                <input
                  type="email"
                  placeholder="votre adresse email"
                  value={email}
                  onChange={(e) => handleChangeEmail(e.target.value)}
                />
                <button
                  className="btn-footer-newsletter"
                  onClick={(e) => onNewsletterFormSubmit(e)}
                >
                  <Send />
                </button>
              </form>
              {error.status && (
                <Errors
                  className="error-authform"
                  backgroundColor={"transparent"}
                  textColor={"#e94b32"}
                  message={error.message}
                />
              )}
            </div>
          </div>
        )}
        {footer !== null && (
          <div className="links">
            {footer.content.map((content, i) => {
              return (
                <ul>
                  <p>{content.titre}</p>
                  {content.items.map((item, n) => {
                    return (
                      <li key={item.titre}>
                        <a
                          href={item.lien}
                          target="_blank"
                          className={i === 1 && n === 0 && "pdf-link"}
                        >
                          {item.titre}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
            <ul>
              <p>{footer.applications.titre}</p>
              {footer.applications.items.map((item) => {
                return (
                  <li key={item.titre}>
                    {item.titre}
                    <ul>
                      {item.plateforme.map((key, n) => {
                        return (
                          <li key={n + key.lien}>
                            <a href={key.lien} target="_blank">
                              <img src={key.picto} className="icon"></img>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="copyright">
          <p>
            Inexploré est édité par l'INREES - Copyright © 2007 - {now} - Tous
            droits réservés
          </p>
          <a href="https://inrees.com/" target="_blank" rel="noreferrer" >
            <img src={InreesWhiteLogo} alt="Inrees" />
          </a>
        </div>
      </div>
      <ContactFooter />
    </footer>
  );
}
