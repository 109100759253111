import React from "react";
import { ReactComponent as Check } from "../../assets/images/svg/check.svg";

export default function Success(props) {
  return (
    <div className="success-authform">
      <Check fill="#27ae5f" />
      <span>{props.message}</span>
    </div>
  );
}
