import React, { useState } from "react";
import { ReactComponent as Envelope } from "../../../assets/images/svg/envelope.svg";
import { ReactComponent as EyeOff } from "../../../assets/images/svg/eye-off.svg";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye.svg";

export default function TextInput(props) {
  const [entryVisible, setEntryVisible] = useState(false);

  const handleChange = (e) => {
    props.handleChangeParent(e.target.value);
  };

  if (props.type === "email") {
    return (
      <div className={props.className}>
        <div className="input-container">
          <input
            autoFocus={props.autoFocus}
            type={props.type}
            value={props.value}
            id={"email"}
            disabled={props.disabled}
            onChange={handleChange}
            autoComplete={'username'}
            name={'email'}
          />
          <label className={props.value && "filled"}>{props.label}</label>
        </div>
        {props.requiredMention ? (
          props.rightIcon ? (
            <div className="required-right-icon">
              <span>requis</span>
              <i>
                <Envelope />
              </i>
            </div>
          ) : (
            <div className="required-only">
              <span>requis</span>
            </div>
          )
        ) : (
          props.rightIcon && (
            <i>
              <Envelope />
            </i>
          )
        )}
      </div>
    );
  }

  if (props.type === "password") {
    return (
      <div className={props.className}>
        <div className="input-container">
          <input
            type={entryVisible ? "text" : "password"}
            autoFocus={props.autoFocus}
            value={props.value}
            disabled={props.disabled}
            onChange={handleChange}
            autoComplete={'current-password'}
            ref={props?.passwordRef}
            id={'password'}
            name={'password'}
          />
          <label className={props.value && "filled"}>{props.label}</label>
        </div>
        {props.requiredMention ? (
          props.rightIcon ? (
            <div className="required-right-icon">
              <span>requis</span>
              <i onClick={() => setEntryVisible(!entryVisible)}>
                {entryVisible ? (
                  <EyeOff className="icon" />
                ) : (
                  <Eye className="icon" />
                )}
              </i>
            </div>
          ) : (
            <div className="required-only">
              <span>requis</span>
            </div>
          )
        ) : (
          props.rightIcon && (
            <i
              className="right-icon"
              onClick={() => setEntryVisible(!entryVisible)}
            >
              {entryVisible ? (
                <EyeOff className="icon" />
              ) : (
                <Eye className="icon" />
              )}
            </i>
          )
        )}
      </div>
    );
  }

  if (props.type === "text") {
    return (
      <div className={props.className}>
        <div className="input-container">
          <input
            type={props.type}
            value={props.value}
            disabled={props.disabled}
            onChange={handleChange}
          />
          <label className={props.value && "filled"}>{props.label}</label>
        </div>
        {props.requiredMention ? (
          props.rightIcon ? (
            <div className="required-right-icon">
              <span>requis</span>
              <i>
                <Envelope />
              </i>
            </div>
          ) : (
            <div className="required-only">
              <span>requis</span>
            </div>
          )
        ) : (
          props.rightIcon && (
            <i>
              <Envelope />
            </i>
          )
        )}
      </div>
    );
  }
}
