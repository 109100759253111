import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCookies } from "../mixins/cookies";
import * as authActions from "../store/actions/authActions";

/* import pages */
import Login from "../pages/Login";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import Newsletter from "../pages/Newsletter";

export default function Navigation(props) {
  const authReducer = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    function getUserCookies() {
      const response = getCookies();
      if (response) {
        dispatch(authActions.setUserConnected(response));
      }
    }
    getUserCookies();
  }, [authReducer.connected]);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<Login connected={authReducer.connected} />}
      />
      <Route path="*" element={<Login connected={authReducer.connected} />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/newsletter" element={<Newsletter />} />
    </Routes>
  );
}
