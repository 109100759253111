import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NewsletterRegistrationForm from "../components/elements/forms/newsletterRegistrationForm";
import NewsletterPreferencesSaved from "../components/elements/newsletterPreferencesSaved";

export default function Newsletter(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const userEmail = searchParams.get("email");
  const referrer = searchParams.get("referrer");
  const newsletterReducer = useSelector((state) => state.newsletterReducer);

  const navigate = useNavigate();

  useEffect(() => {
    if (newsletterReducer.preferencesUpdated) {
      setTimeout(() => {
        redirectUser();
      }, 2000);
    }
  }, [newsletterReducer.preferencesUpdated]);

  const redirectUser = () => {
    if (referrer !== null) {
      window.location.href = referrer;
    } else {
      navigate("/");
    }
  };

  if (!newsletterReducer.preferencesUpdated) {
    return (
      <NewsletterRegistrationForm userEmail={userEmail} referrer={referrer} />
    );
  } else {
    return <NewsletterPreferencesSaved />;
  }
}
