import React from "react";

export default function TitleBlock(props) {
  return (
    <div className="title-block">
      {props.logo && <img src={props.source}></img>}
      <h1>{props.h1}</h1>
      <h2>{props.h2}</h2>
    </div>
  );
}
