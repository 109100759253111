import React from "react";
import { ReactComponent as Alert } from "../../assets/images/svg/alert.svg";

export default function Errors(props) {
  return (
    <div
      className={props.className}
      style={{ backgroundColor: props.backgroundColor }}
    >
      <Alert color={props.textColor} />
      <span style={{ color: props.textColor }}>{props.message}</span>
    </div>
  );
}
