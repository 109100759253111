import Cookies from "universal-cookie";

export const setCookies = (id, token, nom, prenom) => {
  const cookies = new Cookies();
  cookies.set("INEXPLORE_ID", id, {
    path: "/",
    domain: ".inexplore.com",
    expires: new Date(Date.now() + 2678400000),
  });
  cookies.set("INEXPLORE_TOKEN", token, {
    path: "/",
    domain: ".inexplore.com",
    expires: new Date(Date.now() + 2678400000),
  });
  cookies.set("INEXPLORE_USER", JSON.stringify({ nom: nom, prenom: prenom }), {
    path: "/",
    domain: ".inexplore.com",
    expires: new Date(Date.now() + 2678400000),
  });
};

export const getCookies = () => {
  const cookies = new Cookies();
  const inexploreId = cookies.get("INEXPLORE_ID");
  const inexploreToken = cookies.get("INEXPLORE_TOKEN");
  if (!inexploreId || !inexploreToken) {
    return false;
  } else {
    return true;
  }
};

export const removeCookies = () => {
  const cookies = new Cookies();
  const inexploreId = cookies.remove("INEXPLORE_ID", {
    path: "/",
    domain: ".inexplore.com",
  });
  const inexploreToken = cookies.remove("INEXPLORE_TOKEN", {
    path: "/",
    domain: ".inexplore.com",
  });
  const inexploreUser = cookies.remove("INEXPLORE_USER", {
    path: "/",
    domain: ".inexplore.com",
  });
  if (!inexploreId && !inexploreToken && !inexploreUser) {
    return true;
  } else {
    return false;
  }
};
