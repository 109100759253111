export default [
    {
      id: 250,
      name: 'France',
      code: 'fr',
      dialcode: '+33',
      flag: '🇫🇷',
      unicode: 'U+1F1EB U+1F1F7',
    },
    {
        id: 756,
        name: 'Suisse',
        code: 'ch',
        dialcode: '+41',
        flag: '🇨🇭',
        unicode: 'U+1F1E8 U+1F1ED',
      },
    {
      id: 56,
      name: 'Belgique',
      code: 'be',
      dialcode: '+32',
      flag: '🇧🇪',
      unicode: 'U+1F1E7 U+1F1EA',
    },
    {
      id: 4,
      name: 'Afghanistan',
      code: 'af',
      dialcode: '+93',
      flag: '🇦🇫',
      unicode: 'U+1F1E6 U+1F1EB',
    },
    {
      id: 710,
      name: 'Afrique du Sud',
      code: 'za',
      dialcode: '+27',
      flag: '🇿🇦',
      unicode: 'U+1F1FF U+1F1E6',
    },
    {
      id: 8,
      name: 'Albanie',
      code: 'al',
      dialcode: '+355',
      flag: '🇦🇱',
      unicode: 'U+1F1E6 U+1F1F1',
    },
    {
      id: 12,
      name: 'Algérie',
      code: 'dz',
      dialcode: '+213',
      flag: '🇩🇿',
      unicode: 'U+1F1E9 U+1F1FF',
    },
    {
      id: 276,
      name: 'Allemagne',
      code: 'de',
      dialcode: '+49',
      flag: '🇩🇪',
      unicode: 'U+1F1E9 U+1F1EA',
    },
    {
      id: 20,
      name: 'Andorre',
      code: 'ad',
      dialcode: '+376',
      flag: '🇦🇩',
      unicode: 'U+1F1E6 U+1F1E9',
    },
    {
      id: 24,
      name: 'Angola',
      code: 'ao',
      dialcode: '+244',
      flag: '🇦🇴',
      unicode: 'U+1F1E6 U+1F1F4',
    },
    {
      id: 28,
      name: 'Antigua-et-Barbuda',
      code: 'ag',
      dialcode: '+1268',
      flag: '🇦🇬',
      unicode: 'U+1F1E6 U+1F1EC',
    },
    {
      id: 682,
      name: 'Arabie saoudite',
      code: 'sa',
      dialcode: '+966',
      flag: '🇸🇦',
      unicode: 'U+1F1F8 U+1F1E6',
    },
    {
      id: 32,
      name: 'Argentine',
      code: 'ar',
      dialcode: '+54',
      flag: '🇦🇷',
      unicode: 'U+1F1E6 U+1F1F7',
    },
    {
      id: 51,
      name: 'Arménie',
      code: 'am',
      dialcode: '+374',
      flag: '🇦🇲',
      unicode: 'U+1F1E6 U+1F1F2',
    },
    {
      id: 36,
      name: 'Australie',
      code: 'au',
      dialcode: '+61',
      flag: '🇦🇺',
      unicode: 'U+1F1E6 U+1F1FA',
    },
    {
      id: 40,
      name: 'Autriche',
      code: 'at',
      dialcode: '+43',
      flag: '🇦🇹',
      unicode: 'U+1F1E6 U+1F1F9',
    },
    {
      id: 31,
      name: 'Azerbaïdjan',
      code: 'az',
      dialcode: '+994',
      flag: '🇦🇿',
      unicode: 'U+1F1E6 U+1F1FF',
    },
    {
      id: 44,
      name: 'Bahamas',
      code: 'bs',
      dialcode: '+1 242',
      flag: '🇧🇸',
      unicode: 'U+1F1E7 U+1F1F8',
    },
    {
      id: 48,
      name: 'Bahreïn',
      code: 'bh',
      dialcode: '+973',
      flag: '🇧🇭',
      unicode: 'U+1F1E7 U+1F1ED',
    },
    {
      id: 50,
      name: 'Bangladesh',
      code: 'bd',
      dialcode: '+880',
      flag: '🇧🇩',
      unicode: 'U+1F1E7 U+1F1E9',
    },
    {
      id: 52,
      name: 'Barbade',
      code: 'bb',
      dialcode: '+1 246',
      flag: '🇧🇧',
      unicode: 'U+1F1E7 U+1F1E7',
    },
    {
      id: 112,
      name: 'Biélorussie',
      code: 'by',
      dialcode: '+375',
      flag: '🇧🇾',
      unicode: 'U+1F1E7 U+1F1FE',
    },
    {
      id: 84,
      name: 'Belize',
      code: 'bz',
      dialcode: '+501',
      flag: '🇧🇿',
      unicode: 'U+1F1E7 U+1F1FF',
    },
    {
      id: 204,
      name: 'Bénin',
      code: 'bj',
      dialcode: '+229',
      flag: '🇧🇯',
      unicode: 'U+1F1E7 U+1F1EF',
    },
    {
      id: 64,
      name: 'Bhoutan',
      code: 'bt',
      dialcode: '+975',
      flag: '🇧🇹',
      unicode: 'U+1F1E7 U+1F1F9',
    },
    {
      id: 68,
      name: 'Bolivie',
      code: 'bo',
      dialcode: '+591',
      flag: '🇧🇴',
      unicode: 'U+1F1E7 U+1F1F4',
    },
    {
      id: 70,
      name: 'Bosnie-Herzégovine',
      code: 'ba',
      dialcode: '+387',
      flag: '🇧🇦',
      unicode: 'U+1F1E7 U+1F1E6',
    },
    {
      id: 72,
      name: 'Botswana',
      code: 'bw',
      dialcode: '+267',
      flag: '🇧🇼',
      unicode: 'U+1F1E7 U+1F1FC',
    },
    {
      id: 76,
      name: 'Brésil',
      code: 'br',
      dialcode: '+55',
      flag: '🇧🇷',
      unicode: 'U+1F1E7 U+1F1F7',
    },
    {
      id: 96,
      name: 'Brunei',
      code: 'bn',
      dialcode: '+673',
      flag: '🇧🇳',
      unicode: 'U+1F1E7 U+1F1F3',
    },
    {
      id: 100,
      name: 'Bulgarie',
      code: 'bg',
      dialcode: '+359',
      flag: '🇧🇬',
      unicode: 'U+1F1E7 U+1F1EC',
    },
    {
      id: 854,
      name: 'Burkina Faso',
      code: 'bf',
      dialcode: '+226',
      flag: '🇧🇫',
      unicode: 'U+1F1E7 U+1F1EB',
    },
    {
      id: 108,
      name: 'Burundi',
      code: 'bi',
      dialcode: '+257',
      flag: '🇧🇮',
      unicode: 'U+1F1E7 U+1F1EE',
    },
    {
      id: 116,
      name: 'Cambodge',
      code: 'kh',
      dialcode: '+855',
      flag: '🇰🇭',
      unicode: 'U+1F1F0 U+1F1ED',
    },
    {
      id: 120,
      name: 'Cameroun',
      code: 'cm',
      dialcode: '+237',
      flag: '🇨🇲',
      unicode: 'U+1F1E8 U+1F1F2',
    },
    {
      id: 124,
      name: 'Canada',
      code: 'ca',
      dialcode: '+1',
      flag: '🇨🇦',
      unicode: 'U+1F1E8 U+1F1E6',
    },
    {
      id: 132,
      name: 'Cap-Vert',
      code: 'cv',
      dialcode: '+238',
      flag: '🇨🇻',
      unicode: 'U+1F1E8 U+1F1FB',
    },
    {
      id: 140,
      name: 'République centrafricaine',
      code: 'cf',
      dialcode: '+236',
      flag: '🇨🇫',
      unicode: 'U+1F1E8 U+1F1EB',
    },
    {
      id: 152,
      name: 'Chili',
      code: 'cl',
      dialcode: '+56',
      flag: '🇨🇱',
      unicode: 'U+1F1E8 U+1F1F1',
    },
    {
      id: 156,
      name: 'Chine',
      code: 'cn',
      dialcode: '+86',
      flag: '🇨🇳',
      unicode: 'U+1F1E8 U+1F1F3',
    },
    {
      id: 196,
      name: 'Chypre (pays)',
      code: 'cy',
      dialcode: '+537',
      flag: '🇨🇾',
      unicode: 'U+1F1E8 U+1F1FE',
    },
    {
      id: 170,
      name: 'Colombie',
      code: 'co',
      dialcode: '+57',
      flag: '🇨🇴',
      unicode: 'U+1F1E8 U+1F1F4',
    },
    {
      id: 174,
      name: 'Comores (pays)',
      code: 'km',
      dialcode: '+269',
      flag: '🇰🇲',
      unicode: 'U+1F1F0 U+1F1F2',
    },
    {
      id: 178,
      name: 'République du Congo',
      code: 'cg',
      dialcode: '+242',
      flag: '🇨🇬',
      unicode: 'U+1F1E8 U+1F1EC',
    },
    {
      id: 180,
      name: 'République démocratique du Congo',
      code: 'cd',
      dialcode: '+243',
      flag: '🇨🇩',
      unicode: 'U+1F1E8 U+1F1E9',
    },
    {
      id: 410,
      name: 'Corée du Sud',
      code: 'kr',
      dialcode: '+82',
      flag: '🇰🇷',
      unicode: 'U+1F1F0 U+1F1F7',
    },
    {
      id: 408,
      name: 'Corée du Nord',
      code: 'kp',
      dialcode: '+850',
      flag: '🇰🇵',
      unicode: 'U+1F1F0 U+1F1F5',
    },
    {
      id: 188,
      name: 'Costa Rica',
      code: 'cr',
      dialcode: '+506',
      flag: '🇨🇷',
      unicode: 'U+1F1E8 U+1F1F7',
    },
    {
      id: 384,
      name: "Côte d'Ivoire",
      code: 'ci',
      dialcode: '+225',
      flag: '🇨🇮',
      unicode: 'U+1F1E8 U+1F1EE',
    },
    {
      id: 191,
      name: 'Croatie',
      code: 'hr',
      dialcode: '+385',
      flag: '🇭🇷',
      unicode: 'U+1F1ED U+1F1F7',
    },
    {
      id: 192,
      name: 'Cuba',
      code: 'cu',
      dialcode: '+53',
      flag: '🇨🇺',
      unicode: 'U+1F1E8 U+1F1FA',
    },
    {
      id: 208,
      name: 'Danemark',
      code: 'dk',
      dialcode: '+45',
      flag: '🇩🇰',
      unicode: 'U+1F1E9 U+1F1F0',
    },
    {
      id: 262,
      name: 'Djibouti',
      code: 'dj',
      dialcode: '+253',
      flag: '🇩🇯',
      unicode: 'U+1F1E9 U+1F1EF',
    },
    {
      id: 214,
      name: 'République dominicaine',
      code: 'do',
      dialcode: '+1 849',
      flag: '🇩🇴',
      unicode: 'U+1F1E9 U+1F1F4',
    },
    {
      id: 212,
      name: 'Dominique',
      code: 'dm',
      dialcode: '+1 767',
      flag: '🇩🇲',
      unicode: 'U+1F1E9 U+1F1F2',
    },
    {
      id: 818,
      name: 'Égypte',
      code: 'eg',
      dialcode: '+20',
      flag: '🇪🇬',
      unicode: 'U+1F1EA U+1F1EC',
    },
    {
      id: 222,
      name: 'Salvador',
      code: 'sv',
      dialcode: '+503',
      flag: '🇸🇻',
      unicode: 'U+1F1F8 U+1F1FB',
    },
    {
      id: 784,
      name: 'Émirats arabes unis',
      code: 'ae',
      dialcode: '+971',
      flag: '🇦🇪',
      unicode: 'U+1F1E6 U+1F1EA',
    },
    {
      id: 218,
      name: 'Équateur (pays)',
      code: 'ec',
      dialcode: '+593',
      flag: '🇪🇨',
      unicode: 'U+1F1EA U+1F1E8',
    },
    {
      id: 232,
      name: 'Érythrée',
      code: 'er',
      dialcode: '+291',
      flag: '🇪🇷',
      unicode: 'U+1F1EA U+1F1F7',
    },
    {
      id: 724,
      name: 'Espagne',
      code: 'es',
      dialcode: '+34',
      flag: '🇪🇸',
      unicode: 'U+1F1EA U+1F1F8',
    },
    {
      id: 233,
      name: 'Estonie',
      code: 'ee',
      dialcode: '+372',
      flag: '🇪🇪',
      unicode: 'U+1F1EA U+1F1EA',
    },
    {
      id: 840,
      name: 'États-Unis',
      code: 'us',
      dialcode: '+1',
      flag: '🇺🇸',
      unicode: 'U+1F1FA U+1F1F8',
    },
    {
      id: 231,
      name: 'Éthiopie',
      code: 'et',
      dialcode: '+251',
      flag: '🇪🇹',
      unicode: 'U+1F1EA U+1F1F9',
    },
    {
      id: 242,
      name: 'Fidji',
      code: 'fj',
      dialcode: '+679',
      flag: '🇫🇯',
      unicode: 'U+1F1EB U+1F1EF',
    },
    {
      id: 246,
      name: 'Finlande',
      code: 'fi',
      dialcode: '+358',
      flag: '🇫🇮',
      unicode: 'U+1F1EB U+1F1EE',
    },
    {
      id: 266,
      name: 'Gabon',
      code: 'ga',
      dialcode: '+241',
      flag: '🇬🇦',
      unicode: 'U+1F1EC U+1F1E6',
    },
    {
      id: 270,
      name: 'Gambie',
      code: 'gm',
      dialcode: '+220',
      flag: '🇬🇲',
      unicode: 'U+1F1EC U+1F1F2',
    },
    {
      id: 268,
      name: 'Géorgie (pays)',
      code: 'ge',
      dialcode: '+995',
      flag: '🇬🇪',
      unicode: 'U+1F1EC U+1F1EA',
    },
    {
      id: 288,
      name: 'Ghana',
      code: 'gh',
      dialcode: '+233',
      flag: '🇬🇭',
      unicode: 'U+1F1EC U+1F1ED',
    },
    {
      id: 300,
      name: 'Grèce',
      code: 'gr',
      dialcode: '+30',
      flag: '🇬🇷',
      unicode: 'U+1F1EC U+1F1F7',
    },
    {
      id: 308,
      name: 'Grenade (pays)',
      code: 'gd',
      dialcode: '+1 473',
      flag: '🇬🇩',
      unicode: 'U+1F1EC U+1F1E9',
    },
    {
      id: 320,
      name: 'Guatemala',
      code: 'gt',
      dialcode: '+502',
      flag: '🇬🇹',
      unicode: 'U+1F1EC U+1F1F9',
    },
    {
      id: 324,
      name: 'Guinée',
      code: 'gn',
      dialcode: '+224',
      flag: '🇬🇳',
      unicode: 'U+1F1EC U+1F1F3',
    },
    {
      id: 624,
      name: 'Guinée-Bissau',
      code: 'gw',
      dialcode: '+245',
      flag: '🇬🇼',
      unicode: 'U+1F1EC U+1F1FC',
    },
    {
      id: 226,
      name: 'Guinée équatoriale',
      code: 'gq',
      dialcode: '+240',
      flag: '🇬🇶',
      unicode: 'U+1F1EC U+1F1F6',
    },
    {
      id: 328,
      name: 'Guyana',
      code: 'gy',
      dialcode: '+595',
      flag: '🇬🇾',
      unicode: 'U+1F1EC U+1F1FE',
    },
    {
      id: 332,
      name: 'Haïti',
      code: 'ht',
      dialcode: '+509',
      flag: '🇭🇹',
      unicode: 'U+1F1ED U+1F1F9',
    },
    {
      id: 340,
      name: 'Honduras',
      code: 'hn',
      dialcode: '+504',
      flag: '🇭🇳',
      unicode: 'U+1F1ED U+1F1F3',
    },
    {
      id: 348,
      name: 'Hongrie',
      code: 'hu',
      dialcode: '+36',
      flag: '🇭🇺',
      unicode: 'U+1F1ED U+1F1FA',
    },
    {
      id: 356,
      name: 'Inde',
      code: 'in',
      dialcode: '+91',
      flag: '🇮🇳',
      unicode: 'U+1F1EE U+1F1F3',
    },
    {
      id: 360,
      name: 'Indonésie',
      code: 'id',
      dialcode: '+62',
      flag: '🇮🇩',
      unicode: 'U+1F1EE U+1F1E9',
    },
    {
      id: 364,
      name: 'Iran',
      code: 'ir',
      dialcode: '+98',
      flag: '🇮🇷',
      unicode: 'U+1F1EE U+1F1F7',
    },
    {
      id: 368,
      name: 'Irak',
      code: 'iq',
      dialcode: '+964',
      flag: '🇮🇶',
      unicode: 'U+1F1EE U+1F1F6',
    },
    {
      id: 372,
      name: 'Irlande (pays)',
      code: 'ie',
      dialcode: '+353',
      flag: '🇮🇪',
      unicode: 'U+1F1EE U+1F1EA',
    },
    {
      id: 352,
      name: 'Islande',
      code: 'is',
      dialcode: '+354',
      flag: '🇮🇸',
      unicode: 'U+1F1EE U+1F1F8',
    },
    {
      id: 376,
      name: 'Israël',
      code: 'il',
      dialcode: '+972',
      flag: '🇮🇱',
      unicode: 'U+1F1EE U+1F1F1',
    },
    {
      id: 380,
      name: 'Italie',
      code: 'it',
      dialcode: '+39',
      flag: '🇮🇹',
      unicode: 'U+1F1EE U+1F1F9',
    },
    {
      id: 388,
      name: 'Jamaïque',
      code: 'jm',
      dialcode: '+1 876',
      flag: '🇯🇲',
      unicode: 'U+1F1EF U+1F1F2',
    },
    {
      id: 392,
      name: 'Japon',
      code: 'jp',
      dialcode: '+81',
      flag: '🇯🇵',
      unicode: 'U+1F1EF U+1F1F5',
    },
    {
      id: 400,
      name: 'Jordanie',
      code: 'jo',
      dialcode: '+962',
      flag: '🇯🇴',
      unicode: 'U+1F1EF U+1F1F4',
    },
    {
      id: 398,
      name: 'Kazakhstan',
      code: 'kz',
      dialcode: '+7 7',
      flag: '🇰🇿',
      unicode: 'U+1F1F0 U+1F1FF',
    },
    {
      id: 404,
      name: 'Kenya',
      code: 'ke',
      dialcode: '+254',
      flag: '🇰🇪',
      unicode: 'U+1F1F0 U+1F1EA',
    },
    {
      id: 417,
      name: 'Kirghizistan',
      code: 'kg',
      dialcode: '+996',
      flag: '🇰🇬',
      unicode: 'U+1F1F0 U+1F1EC',
    },
    {
      id: 296,
      name: 'Kiribati',
      code: 'ki',
      dialcode: '+686',
      flag: '🇰🇮',
      unicode: 'U+1F1F0 U+1F1EE',
    },
    {
      id: 414,
      name: 'Koweït',
      code: 'kw',
      dialcode: '+965',
      flag: '🇰🇼',
      unicode: 'U+1F1F0 U+1F1FC',
    },
    {
      id: 418,
      name: 'Laos',
      code: 'la',
      dialcode: '+856',
      flag: '🇱🇦',
      unicode: 'U+1F1F1 U+1F1E6',
    },
    {
      id: 426,
      name: 'Lesotho',
      code: 'ls',
      dialcode: '+266',
      flag: '🇱🇸',
      unicode: 'U+1F1F1 U+1F1F8',
    },
    {
      id: 428,
      name: 'Lettonie',
      code: 'lv',
      dialcode: '+371',
      flag: '🇱🇻',
      unicode: 'U+1F1F1 U+1F1FB',
    },
    {
      id: 422,
      name: 'Liban',
      code: 'lb',
      dialcode: '+961',
      flag: '🇱🇧',
      unicode: 'U+1F1F1 U+1F1E7',
    },
    {
      id: 430,
      name: 'Liberia',
      code: 'lr',
      dialcode: '+231',
      flag: '🇱🇷',
      unicode: 'U+1F1F1 U+1F1F7',
    },
    {
      id: 434,
      name: 'Libye',
      code: 'ly',
      dialcode: '+218',
      flag: '🇱🇾',
      unicode: 'U+1F1F1 U+1F1FE',
    },
    {
      id: 438,
      name: 'Liechtenstein',
      code: 'li',
      dialcode: '+423',
      flag: '🇱🇮',
      unicode: 'U+1F1F1 U+1F1EE',
    },
    {
      id: 440,
      name: 'Lituanie',
      code: 'lt',
      dialcode: '+370',
      flag: '🇱🇹',
      unicode: 'U+1F1F1 U+1F1F9',
    },
    {
      id: 442,
      name: 'Luxembourg (pays)',
      code: 'lu',
      dialcode: '+352',
      flag: '🇱🇺',
      unicode: 'U+1F1F1 U+1F1FA',
    },
    {
      id: 807,
      name: 'Macédoine du Nord',
      code: 'mk',
      dialcode: '+389',
      flag: '🇲🇰',
      unicode: 'U+1F1F2 U+1F1F0',
    },
    {
      id: 450,
      name: 'Madagascar',
      code: 'mg',
      dialcode: '+261',
      flag: '🇲🇬',
      unicode: 'U+1F1F2 U+1F1EC',
    },
    {
      id: 458,
      name: 'Malaisie',
      code: 'my',
      dialcode: '+60',
      flag: '🇲🇾',
      unicode: 'U+1F1F2 U+1F1FE',
    },
    {
      id: 454,
      name: 'Malawi',
      code: 'mw',
      dialcode: '+265',
      flag: '🇲🇼',
      unicode: 'U+1F1F2 U+1F1FC',
    },
    {
      id: 462,
      name: 'Maldives',
      code: 'mv',
      dialcode: '+960',
      flag: '🇲🇻',
      unicode: 'U+1F1F2 U+1F1FB',
    },
    {
      id: 466,
      name: 'Mali',
      code: 'ml',
      dialcode: '+223',
      flag: '🇲🇱',
      unicode: 'U+1F1F2 U+1F1F1',
    },
    {
      id: 470,
      name: 'Malte',
      code: 'mt',
      dialcode: '+356',
      flag: '🇲🇹',
      unicode: 'U+1F1F2 U+1F1F9',
    },
    {
      id: 504,
      name: 'Maroc',
      code: 'ma',
      dialcode: '+212',
      flag: '🇲🇦',
      unicode: 'U+1F1F2 U+1F1E6',
    },
    {
      id: 584,
      name: 'Îles Marshall (pays)',
      code: 'mh',
      dialcode: '+692',
      flag: '🇲🇭',
      unicode: 'U+1F1F2 U+1F1ED',
    },
    {
      id: 480,
      name: 'Maurice (pays)',
      code: 'mu',
      dialcode: '+230',
      flag: '🇲🇺',
      unicode: 'U+1F1F2 U+1F1FA',
    },
    {
      id: 478,
      name: 'Mauritanie',
      code: 'mr',
      dialcode: '+222',
      flag: '🇲🇷',
      unicode: 'U+1F1F2 U+1F1F7',
    },
    {
      id: 484,
      name: 'Mexique',
      code: 'mx',
      dialcode: '+52',
      flag: '🇲🇽',
      unicode: 'U+1F1F2 U+1F1FD',
    },
    {
      id: 583,
      name: 'États fédérés de Micronésie (pays)',
      code: 'fm',
      dialcode: '+691',
      flag: '🇫🇲',
      unicode: 'U+1F1EB U+1F1F2',
    },
    {
      id: 498,
      name: 'Moldavie',
      code: 'md',
      dialcode: '+373',
      flag: '🇲🇩',
      unicode: 'U+1F1F2 U+1F1E9',
    },
    {
      id: 492,
      name: 'Monaco',
      code: 'mc',
      dialcode: '+377',
      flag: '🇲🇨',
      unicode: 'U+1F1F2 U+1F1E8',
    },
    {
      id: 496,
      name: 'Mongolie',
      code: 'mn',
      dialcode: '+976',
      flag: '🇲🇳',
      unicode: 'U+1F1F2 U+1F1F3',
    },
    {
      id: 499,
      name: 'Monténégro',
      code: 'me',
      dialcode: '+382',
      flag: '🇲🇪',
      unicode: 'U+1F1F2 U+1F1EA',
    },
    {
      id: 508,
      name: 'Mozambique',
      code: 'mz',
      dialcode: '+258',
      flag: '🇲🇿',
      unicode: 'U+1F1F2 U+1F1FF',
    },
    {
      id: 104,
      name: 'Birmanie',
      code: 'mm',
      dialcode: '+95',
      flag: '🇲🇲',
      unicode: 'U+1F1F2 U+1F1F2',
    },
    {
      id: 516,
      name: 'Namibie',
      code: 'na',
      dialcode: '+264',
      flag: '🇳🇦',
      unicode: 'U+1F1F3 U+1F1E6',
    },
    {
      id: 520,
      name: 'Nauru',
      code: 'nr',
      dialcode: '+674',
      flag: '🇳🇷',
      unicode: 'U+1F1F3 U+1F1F7',
    },
    {
      id: 524,
      name: 'Népal',
      code: 'np',
      dialcode: '+977',
      flag: '🇳🇵',
      unicode: 'U+1F1F3 U+1F1F5',
    },
    {
      id: 558,
      name: 'Nicaragua',
      code: 'ni',
      dialcode: '+505',
      flag: '🇳🇮',
      unicode: 'U+1F1F3 U+1F1EE',
    },
    {
      id: 562,
      name: 'Niger',
      code: 'ne',
      dialcode: '+227',
      flag: '🇳🇪',
      unicode: 'U+1F1F3 U+1F1EA',
    },
    {
      id: 566,
      name: 'Nigeria',
      code: 'ng',
      dialcode: '+234',
      flag: '🇳🇬',
      unicode: 'U+1F1F3 U+1F1EC',
    },
    {
      id: 578,
      name: 'Norvège',
      code: 'no',
      dialcode: '+47',
      flag: '🇳🇴',
      unicode: 'U+1F1F3 U+1F1F4',
    },
    {
      id: 554,
      name: 'Nouvelle-Zélande',
      code: 'nz',
      dialcode: '+64',
      flag: '🇳🇿',
      unicode: 'U+1F1F3 U+1F1FF',
    },
    {
      id: 512,
      name: 'Oman',
      code: 'om',
      dialcode: '+968',
      flag: '🇴🇲',
      unicode: 'U+1F1F4 U+1F1F2',
    },
    {
      id: 800,
      name: 'Ouganda',
      code: 'ug',
      dialcode: '+256',
      flag: '🇺🇬',
      unicode: 'U+1F1FA U+1F1EC',
    },
    {
      id: 860,
      name: 'Ouzbékistan',
      code: 'uz',
      dialcode: '+998',
      flag: '🇺🇿',
      unicode: 'U+1F1FA U+1F1FF',
    },
    {
      id: 586,
      name: 'Pakistan',
      code: 'pk',
      dialcode: '+92',
      flag: '🇵🇰',
      unicode: 'U+1F1F5 U+1F1F0',
    },
    {
      id: 585,
      name: 'Palaos',
      code: 'pw',
      dialcode: '+680',
      flag: '🇵🇼',
      unicode: 'U+1F1F5 U+1F1FC',
    },
    {
      id: 591,
      name: 'Panama',
      code: 'pa',
      dialcode: '+507',
      flag: '🇵🇦',
      unicode: 'U+1F1F5 U+1F1E6',
    },
    {
      id: 598,
      name: 'Papouasie-Nouvelle-Guinée',
      code: 'pg',
      dialcode: '+675',
      flag: '🇵🇬',
      unicode: 'U+1F1F5 U+1F1EC',
    },
    {
      id: 600,
      name: 'Paraguay',
      code: 'py',
      dialcode: '+595',
      flag: '🇵🇾',
      unicode: 'U+1F1F5 U+1F1FE',
    },
    {
      id: 528,
      name: 'Pays-Bas',
      code: 'nl',
      dialcode: '+31',
      flag: '🇳🇱',
      unicode: 'U+1F1F3 U+1F1F1',
    },
    {
      id: 604,
      name: 'Pérou',
      code: 'pe',
      dialcode: '+51',
      flag: '🇵🇪',
      unicode: 'U+1F1F5 U+1F1EA',
    },
    {
      id: 608,
      name: 'Philippines',
      code: 'ph',
      dialcode: '+63',
      flag: '🇵🇭',
      unicode: 'U+1F1F5 U+1F1ED',
    },
    {
      id: 616,
      name: 'Pologne',
      code: 'pl',
      dialcode: '+48',
      flag: '🇵🇱',
      unicode: 'U+1F1F5 U+1F1F1',
    },
    {
      id: 620,
      name: 'Portugal',
      code: 'pt',
      dialcode: '+351',
      flag: '🇵🇹',
      unicode: 'U+1F1F5 U+1F1F9',
    },
    {
      id: 634,
      name: 'Qatar',
      code: 'qa',
      dialcode: '+974',
      flag: '🇶🇦',
      unicode: 'U+1F1F6 U+1F1E6',
    },
    {
      id: 642,
      name: 'Roumanie',
      code: 'ro',
      dialcode: '+40',
      flag: '🇷🇴',
      unicode: 'U+1F1F7 U+1F1F4',
    },
    {
      id: 826,
      name: 'Royaume-Uni',
      code: 'gb',
      dialcode: '+44',
      flag: '🇬🇧',
      unicode: 'U+1F1EC U+1F1E7',
    },
    {
      id: 643,
      name: 'Russie',
      code: 'ru',
      dialcode: '+7',
      flag: '🇷🇺',
      unicode: 'U+1F1F7 U+1F1FA',
    },
    {
      id: 646,
      name: 'Rwanda',
      code: 'rw',
      dialcode: '+250',
      flag: '🇷🇼',
      unicode: 'U+1F1F7 U+1F1FC',
    },
    {
      id: 659,
      name: 'Saint-Christophe-et-Niévès',
      code: 'kn',
      dialcode: '+1 869',
      flag: '🇰🇳',
      unicode: 'U+1F1F0 U+1F1F3',
    },
    {
      id: 674,
      name: 'Saint-Marin',
      code: 'sm',
      dialcode: '+378',
      flag: '🇸🇲',
      unicode: 'U+1F1F8 U+1F1F2',
    },
    {
      id: 670,
      name: 'Saint-Vincent-et-les-Grenadines',
      code: 'vc',
      dialcode: '+1 784',
      flag: '🇻🇨',
      unicode: 'U+1F1FB U+1F1E8',
    },
    {
      id: 662,
      name: 'Sainte-Lucie',
      code: 'lc',
      dialcode: '+1 758',
      flag: '🇱🇨',
      unicode: 'U+1F1F1 U+1F1E8',
    },
    {
      id: 90,
      name: 'Îles Salomon',
      code: 'sb',
      dialcode: '+677',
      flag: '🇸🇧',
      unicode: 'U+1F1F8 U+1F1E7',
    },
    {
      id: 882,
      name: 'Samoa',
      code: 'ws',
      dialcode: '+685',
      flag: '🇼🇸',
      unicode: 'U+1F1FC U+1F1F8',
    },
    {
      id: 678,
      name: 'Sao Tomé-et-Principe',
      code: 'st',
      dialcode: '+239',
      flag: '🇸🇹',
      unicode: 'U+1F1F8 U+1F1F9',
    },
    {
      id: 686,
      name: 'Sénégal',
      code: 'sn',
      dialcode: '+221',
      flag: '🇸🇳',
      unicode: 'U+1F1F8 U+1F1F3',
    },
    {
      id: 688,
      name: 'Serbie',
      code: 'rs',
      dialcode: '+381',
      flag: '🇷🇸',
      unicode: 'U+1F1F7 U+1F1F8',
    },
    {
      id: 690,
      name: 'Seychelles',
      code: 'sc',
      dialcode: '+248',
      flag: '🇸🇨',
      unicode: 'U+1F1F8 U+1F1E8',
    },
    {
      id: 694,
      name: 'Sierra Leone',
      code: 'sl',
      dialcode: '+232',
      flag: '🇸🇱',
      unicode: 'U+1F1F8 U+1F1F1',
    },
    {
      id: 702,
      name: 'Singapour',
      code: 'sg',
      dialcode: '+65',
      flag: '🇸🇬',
      unicode: 'U+1F1F8 U+1F1EC',
    },
    {
      id: 703,
      name: 'Slovaquie',
      code: 'sk',
      dialcode: '+421',
      flag: '🇸🇰',
      unicode: 'U+1F1F8 U+1F1F0',
    },
    {
      id: 705,
      name: 'Slovénie',
      code: 'si',
      dialcode: '+386',
      flag: '🇸🇮',
      unicode: 'U+1F1F8 U+1F1EE',
    },
    {
      id: 706,
      name: 'Somalie',
      code: 'so',
      dialcode: '+252',
      flag: '🇸🇴',
      unicode: 'U+1F1F8 U+1F1F4',
    },
    {
      id: 729,
      name: 'Soudan',
      code: 'sd',
      dialcode: '+249',
      flag: '🇸🇩',
      unicode: 'U+1F1F8 U+1F1E9',
    },
    {
      id: 144,
      name: 'Sri Lanka',
      code: 'lk',
      dialcode: '+94',
      flag: '🇱🇰',
      unicode: 'U+1F1F1 U+1F1F0',
    },
    {
      id: 752,
      name: 'Suède',
      code: 'se',
      dialcode: '+46',
      flag: '🇸🇪',
      unicode: 'U+1F1F8 U+1F1EA',
    },
    {
      id: 740,
      name: 'Suriname',
      code: 'sr',
      dialcode: '+597',
      flag: '🇸🇷',
      unicode: 'U+1F1F8 U+1F1F7',
    },
    {
      id: 748,
      name: 'Eswatini',
      code: 'sz',
      dialcode: '+268',
      flag: '🇸🇿',
      unicode: 'U+1F1F8 U+1F1FF',
    },
    {
      id: 760,
      name: 'Syrie',
      code: 'sy',
      dialcode: '+963',
      flag: '🇸🇾',
      unicode: 'U+1F1F8 U+1F1FE',
    },
    {
      id: 762,
      name: 'Tadjikistan',
      code: 'tj',
      dialcode: '+992',
      flag: '🇹🇯',
      unicode: 'U+1F1F9 U+1F1EF',
    },
    {
      id: 834,
      name: 'Tanzanie',
      code: 'tz',
      dialcode: '+255',
      flag: '🇹🇿',
      unicode: 'U+1F1F9 U+1F1FF',
    },
    {
      id: 148,
      name: 'Tchad',
      code: 'td',
      dialcode: '+235',
      flag: '🇹🇩',
      unicode: 'U+1F1F9 U+1F1E9',
    },
    {
      id: 203,
      name: 'République Tchèque',
      code: 'cz',
      dialcode: '+420',
      flag: '🇨🇿',
      unicode: 'U+1F1E8 U+1F1FF',
    },
    {
      id: 764,
      name: 'Thaïlande',
      code: 'th',
      dialcode: '+66',
      flag: '🇹🇭',
      unicode: 'U+1F1F9 U+1F1ED',
    },
    {
      id: 626,
      name: 'Timor oriental',
      code: 'tl',
      dialcode: '+670',
      flag: '🇹🇱',
      unicode: 'U+1F1F9 U+1F1F1',
    },
    {
      id: 768,
      name: 'Togo',
      code: 'tg',
      dialcode: '+228',
      flag: '🇹🇬',
      unicode: 'U+1F1F9 U+1F1EC',
    },
    {
      id: 776,
      name: 'Tonga',
      code: 'to',
      dialcode: '+676',
      flag: '🇹🇴',
      unicode: 'U+1F1F9 U+1F1F4',
    },
    {
      id: 780,
      name: 'Trinité-et-Tobago',
      code: 'tt',
      dialcode: '+1 868',
      flag: '🇹🇹',
      unicode: 'U+1F1F9 U+1F1F9',
    },
    {
      id: 788,
      name: 'Tunisie',
      code: 'tn',
      dialcode: '+216',
      flag: '🇹🇳',
      unicode: 'U+1F1F9 U+1F1F3',
    },
    {
      id: 795,
      name: 'Turkménistan',
      code: 'tm',
      dialcode: '+993',
      flag: '🇹🇲',
      unicode: 'U+1F1F9 U+1F1F2',
    },
    {
      id: 792,
      name: 'Turquie',
      code: 'tr',
      dialcode: '+90',
      flag: '🇹🇷',
      unicode: 'U+1F1F9 U+1F1F7',
    },
    {
      id: 798,
      name: 'Tuvalu',
      code: 'tv',
      dialcode: '+688',
      flag: '🇹🇻',
      unicode: 'U+1F1F9 U+1F1FB',
    },
    {
      id: 804,
      name: 'Ukraine',
      code: 'ua',
      dialcode: '+380',
      flag: '🇺🇦',
      unicode: 'U+1F1FA U+1F1E6',
    },
    {
      id: 858,
      name: 'Uruguay',
      code: 'uy',
      dialcode: '+598',
      flag: '🇺🇾',
      unicode: 'U+1F1FA U+1F1FE',
    },
    {
      id: 548,
      name: 'Vanuatu',
      code: 'vu',
      dialcode: '+678',
      flag: '🇻🇺',
      unicode: 'U+1F1FB U+1F1FA',
    },
    {
      id: 862,
      name: 'Venezuela',
      code: 've',
      dialcode: '+58',
      flag: '🇻🇪',
      unicode: 'U+1F1FB U+1F1EA',
    },
    {
      id: 704,
      name: 'Viêt Nam',
      code: 'vn',
      dialcode: '+84',
      flag: '🇻🇳',
      unicode: 'U+1F1FB U+1F1F3',
    },
    {
      id: 887,
      name: 'Yémen',
      code: 'ye',
      dialcode: '+967',
      flag: '🇾🇪',
      unicode: 'U+1F1FE U+1F1EA',
    },
    {
      id: 894,
      name: 'Zambie',
      code: 'zm',
      dialcode: '+260',
      flag: '🇿🇲',
      unicode: 'U+1F1FF U+1F1F2',
    },
    {
      id: 716,
      name: 'Zimbabwe',
      code: 'zw',
      dialcode: '+263',
      flag: '🇿🇼',
      unicode: 'U+1F1FF U+1F1FC',
    },
  ];
  