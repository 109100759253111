import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as newsletterActions from "../../../store/actions/newsletterActions";
import * as authActions from "../../../store/actions/authActions";
import VerificationEmailCode from "../../elements/forms/VerificationEmailCode";
import SEOMixins from "../../mixins/SEOMixins";
import Errors from "../../layout/Errors";
import AuthHeader from "../../layout/AuthHeader";
import TextInput from "../../elements/forms/TextInput";
import TitleBlock from "../../elements/TitleBlock";
import Loader from "../../elements/loader";
import MyinexploreLogo from "../../../assets/images/svg/my-inexplore-logo.svg";

export default function NewsletterRegistrationForm(props) {
  const [verificationCode, setVerificationCode] = useState("");
  const [gender, setGender] = useState("");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState("");
  const [offers, setOffers] = useState({
    notif: true,
    promo: true,
    actu: true,
    film: true,
  });
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    submit: false,
  });
  const newsletterReducer = useSelector((state) => state.newsletterReducer);
  let initialized = false;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      if (props.userEmail === null) {
        if (props.referrer !== null) {
          window.location.href = props.referrer;
        } else {
          if (newsletterReducer.code === null) {
            navigate("/");
          }
        }
      } else {
        if (newsletterReducer.code === null) {
          setTimeout(() => {
            generateVerificationCode(props.userEmail);
          }, 1000);
        }
      }
    }
  }, []);

  const generateVerificationCode = async (email) => {
    try {
      const response = await dispatch(
        newsletterActions.generateEmailCode(email)
      );
    } catch (error) {
      console.log(error);
    }
  };

  /* States handler */

  const handleChangeFirstname = (value) => {
    setFirstname(value);
    setErrors({ ...errors, firstName: false });
  };
  const handleChangeLastname = (value) => {
    setLastName(value);
    setErrors({ ...errors, lastName: false });
  };

  const handleChangeCode = async (value) => {
    if (value) {
      if (value === newsletterReducer.code) {
        try {
          const response = await dispatch(
            authActions.isEmailExist({ email: newsletterReducer.email })
          );
          if (response.infos.tab.exist === 1) {
            if(response.infos.user.civilite) setGender(response.infos.user.civilite);
            if(response.infos.user.prenom) setFirstname(response.infos.user.prenom);
            if(response.infos.user.nom) setLastName(response.infos.user.nom);
            setOffers({
              notif: response.infos.user.notif === 1 ? true : false,
              promo: response.infos.user.promo === 1 ? true : false,
              actu: response.infos.user.actu === 1 ? true : false,
              film: response.infos.user.film === 1 ? true : false,
            });
          }
          setTimeout(() => {
            setVerificationCode(value);
          }, 1000);
        } catch (error) {}
      }
    } else {
      setVerificationCode(value);
    }
  };

  /* submit form */

  const submitNewsletterRegisterForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newErrors = {
      firstName: false,
      lastName: false,
      submit: false,
    };
    if (firstName.length < 1) {
      newErrors.firstName = true;
    }
    if (lastName.length < 1) {
      newErrors.lastName = true;
    }
    setErrors(newErrors);
    if (!newErrors.firstName && !newErrors.lastName) {
      setLoading(true);
      createUserKey();
    }
  };

  const createUserKey = async () => {
    try {
      const response = await dispatch(
        authActions.createUserKey({ email: newsletterReducer.email })
      );

      if (response.cle) {
        updateUserNewsletterPreferences({
          cle: response.cle,
          email: newsletterReducer.email,
          civilite: gender,
          prenom: firstName,
          nom: lastName,
          notif: offers.notif === true ? 1 : 0,
          promo: offers.promo === true ? 1 : 0,
          actu: offers.actu === true ? 1 : 0,
          film: offers.film === true ? 1 : 0,
        });
      }
    } catch (error) {
      setErrors({ ...errors, submit: true });
      setLoading(false);
    }
  };

  const updateUserNewsletterPreferences = async (datas) => {
    try {
      const response = await dispatch(
        newsletterActions.createUserNewsletter(datas)
      );
      if (response.status === 201) {
        setLoading(false);
        dispatch(newsletterActions.setUserNewsletterPreferencesSaved());
      }
    } catch (error) {
      setErrors({ ...errors, submit: true });
      setLoading(false);
    }
  };

  const returnLogin = () => {
    dispatch(newsletterActions.removeNewsletterEmailCode());
    if (props.referrer === null) {
      navigate("/");
    } else {
      window.location.href = props.referrer;
    }
  };

  return (
    <div className="main main-auth">
      <SEOMixins
        title="Inexploré - Inscription à la newsletter"
        description="Mise à jour de vos préférences d'abonnement à notre newsletter"
      />
      {newsletterReducer.code !== verificationCode ? (
        <div className="login-wrapper">
          <AuthHeader referrer={props.referrer} />
          <div className="login-container">
            {newsletterReducer.code === null ? (
              <div style={{ width: "100%" }}>
                <TitleBlock
                  logo={true}
                  source={MyinexploreLogo}
                  h1="Un petit moment..."
                  h2="Merci de patienter quelques instant s'il vous plait, le temps que nous traitions votre demande"
                />
                <div className="loader submit-login">
                  <Loader size={18} color="#162a41" />
                </div>
              </div>
            ) : (
              <VerificationEmailCode
                workflow="newsletter"
                handleChangeCodeParent={handleChangeCode}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="register-wrapper">
          <AuthHeader matchCode={true} referrer={props.referrer} />
          <div className="register-container">
            <form
              className="register-form"
              onSubmit={(e) => submitNewsletterRegisterForm(e)}
            >
              <h1>Mes informations personnelles</h1>
              <div className="radio-container">
                <div className="radio-item">
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="Madame"
                    checked={gender === "Madame"}
                    onChange={(e) => setGender("Madame")}
                  />
                  <label htmlFor="female">Madame</label>
                </div>
                <div className="radio-item">
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="Monsieur"
                    checked={gender === "Monsieur"}
                    onChange={(e) => setGender("Monsieur")}
                  />
                  <label htmlFor="male">Monsieur</label>
                </div>
                <div className="radio-item">
                  <input
                    type="radio"
                    id="undefined"
                    name="gender"
                    value="undefined"
                    checked={gender === "undefined"}
                    onChange={(e) => setGender("undefined")}
                  />
                  <label htmlFor="undefined">
                    Je ne préfère pas le préciser
                  </label>
                </div>
                {gender.error && (
                  <Errors
                    className="error-authform"
                    backgroundColor={"rgba(233, 75, 50, 0.08)"}
                    textColor={"#e94b32"}
                    message={gender.message}
                  />
                )}
              </div>
              <table>
                <tbody>
                  <tr>
                    <td className="specific">
                      <TextInput
                        className={
                          !errors.firstName
                            ? "input-floating input-border"
                            : "input-floating input-border-error"
                        }
                        label="Mon prénom"
                        type="text"
                        value={firstName}
                        disabled={false}
                        rightIcon={false}
                        requiredMention={true}
                        handleChangeParent={handleChangeFirstname}
                      ></TextInput>
                      {errors.firstName && (
                        <Errors
                          className="error-authform"
                          backgroundColor={"rgba(233, 75, 50, 0.08)"}
                          textColor={"#e94b32"}
                          message="Vous devez renseigner votre prénom"
                        />
                      )}
                    </td>
                    <td>
                      <TextInput
                        className={
                          !errors.lastName
                            ? "input-floating input-border"
                            : "input-floating input-border-error"
                        }
                        label="Mon nom"
                        type="text"
                        value={lastName}
                        disabled={false}
                        rightIcon={false}
                        requiredMention={true}
                        handleChangeParent={handleChangeLastname}
                      ></TextInput>
                      {errors.lastName && (
                        <Errors
                          className="error-authform"
                          backgroundColor={"rgba(233, 75, 50, 0.08)"}
                          textColor={"#e94b32"}
                          message="Vous devez renseigner votre nom"
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <TextInput
                        className={"input-floating input-border"}
                        label="Mon adresse email"
                        type="email"
                        value={newsletterReducer.email}
                        disabled={true}
                        rightIcon={true}
                        requiredMention={true}
                      ></TextInput>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2 className="margin-title">Je souhaite recevoir....</h2>
              <div className="switch-container margin-switch">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setOffers({ ...offers, promo: e.target.checked })
                    }
                    checked={offers.promo}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="slider-text">
                  Des offres et nouveautés commerciales des univers{" "}
                  <span className="italic">Inexploré.</span>
                </span>
              </div>
              <div className="switch-container margin-switch">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setOffers({ ...offers, actu: e.target.checked })
                    }
                    checked={offers.actu}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="slider-text">
                Des contenus 100% gratuits (articles, podcasts, vidéos…).
                </span>
              </div>
              <div className="switch-container margin-switch">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setOffers({ ...offers, film: e.target.checked })
                    }
                    checked={offers.film}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="slider-text">
                  Des actualités de Sébastien Lilli (rédacteur en chef et
                  créateur d'<span className="italic">Inexploré</span>).
                </span>
              </div>
              <div className="switch-container margin-switch">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setOffers({ ...offers, notif: e.target.checked })
                    }
                    checked={offers.notif}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="slider-text">
                  Les notifications relatives aux abonnements Inexploré
                  magazine, digital et TV (sorties de magazines et de nouvelles
                  vidéos, fin d'abonnement…).
                </span>
              </div>
              <p className="mentions-legales-newsletter margin-mentions-legales ">
                Pour vous tenir informés des points d'évolutions importants
                (CGV, tarifs, conditions d'abonnement) nous vous enverrons une
                newsletter.
                <br />
                Cette dernière étant une obligation légale, il n'est pas
                possible de s'en désabonner.
              </p>
              {errors.submit && (
                <Errors
                  className="error-authform"
                  backgroundColor={"rgba(233, 75, 50, 0.08)"}
                  textColor={"#e94b32"}
                  message={
                    "Une erreur est survenue. Veuillez réessayer ultérieurement"
                  }
                />
              )}
              <div className="buttons-container">
                <div className="buttons-wrapper">
                  {!loading && (
                    <button
                      className="btn-outline-dark-blue cancel-register"
                      type="button"
                      onClick={() => returnLogin()}
                    >
                      Annuler
                    </button>
                  )}
                  <button
                    disabled={loading}
                    className="btn-gradient submit-login"
                    type="submit"
                  >
                    {loading ? <Loader size={12} color="#ffffff" /> : <span>Valider</span>}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
