import React, { useEffect, useState } from "react";
import moment from "moment";
import { ReactComponent as Question } from "../../assets/images/svg/question-mark-circle.svg";
import { ReactComponent as Phone } from "../../assets/images/svg/phone.svg";
import { ReactComponent as Mail } from "../../assets/images/svg/mail.svg";

export default function ContactFooter(props) {
  const [open, setOpen] = useState(null);

  const isOpen = () => {
    const day = moment().isoWeekday();
    const fullTime = moment().hour() + moment().minutes() / 60;
		const daymonth = moment().format("D-M");
		const feries = ["25-12","26-12","29-12","1-1","14-7","11-11"];

		if (day <= 5 && !feries.includes(daymonth)) {
      if (fullTime >= 9.5 && fullTime < 17) {
        if (fullTime >= 12.5 && fullTime < 14) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      } else {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    isOpen();
    let openService = setInterval(() => {
      isOpen();
    }, 300000);
    return () => clearInterval(openService);
  }, []);

  return (
    <div className="contacts">
      <ul>
        <li className="sav" key={"status"}>
          <p className="status">
            Service client
            {open !== null && (
              <span className={open ? "open" : "close"}>
                {open ? "Ouvert" : "Fermé"}
              </span>
            )}
          </p>
          <p>Du lundi au vendredi</p>
          <p>De 09:00 à 12:30 - 14:00 à 17:00</p>
        </li>
      </ul>
      <ul>
        <li key={"FAQ"}>
          <a
            href="https://www.inrees.com/FAQ/"
            target="_blank"
            rel="noreferrer"
          >
            Foire aux questions
            <span>
              <Question className="icon" />
            </span>
          </a>
        </li>
      </ul>
      <ul>
        <li key={"email"}>
          <a
            href="https://www.inrees.com/Contact"
            target="_blank"
            rel="noreferrer"
          >
            Email
            <span>
              <Mail className="icon" />
            </span>
          </a>
        </li>
      </ul>
      <ul>
        <li key={"tel"}>
          {open && (
            <a className="phone">
              Tél: +33 1 78 90 59 56
              <span>
                <Phone className="icon" />
              </span>
            </a>
          )}
        </li>
      </ul>
    </div>
  );
}
