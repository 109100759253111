import React from "react";
import TitleBlock from "./TitleBlock";
import { useDispatch } from "react-redux";
import { removeCookies } from "../../mixins/cookies";
import * as authActions from "../../store/actions/authActions";
import { ReactComponent as Check } from "../../assets/images/svg/check.svg";
import Loader from "./loader";

export default function AuthWrapper(props) {
  const dispatch = useDispatch();

  const logOut = () => {
    removeCookies();
    dispatch(authActions.logOut());
  };

  return (
    <div className="auth-wrapper">
      <Check className="icon" fill="#27ae5f" />
      <TitleBlock
        logo={false}
        h1="Vous êtes connecté à votre compte."
        h2="Vous pouvez dès à présent fermer cette page ou vous déconnecter."
      />
      {props.referrer ? (
        <div className="loader">
          <Loader size={18} color="#162a41" />
        </div>
      ) : (
        <div className="buttons-container">
          <a
            className="btn-gradient btn-mon-compte"
            href="https://my.inexplore.com/"
            target={"_blank"}
          >
            <span>Mon compte</span>
          </a>
          <button
            className="btn-red log-out"
            type="button"
            onClick={() => logOut()}
          >
            Se déconnecter
          </button>
        </div>
      )}
    </div>
  );
}
