import axiosInstance from "../../mixins/axios";

export const SET_USER_EMAIL_NEWSLETTER_CODE = "SET_USER_EMAIL_NEWSLETTER_CODE";
export const SET_USER_EMAIL_NEWSLETTER_PREFERENCES_UPDATED =
  "SET_USER_EMAIL_NEWSLETTER_PREFERENCES_UPDATED";
export const RESET_USER_EMAIL_NEWSLETTER_CODE =
  "RESET_USER_EMAIL_NEWSLETTER_CODE";

export const generateEmailCode = (email) => {
  return async (dispatch) => {
    try {
      const newCode = Math.floor(1000 + Math.random() * 9000).toString();
      const response = await axiosInstance.post("mail/send", {
        email: email,
        sujet: "Inexploré - vérification de votre adresse email",
        message: `Le code de vérification est : <strong> ${newCode} </strong>`,
      });
      if (response.data) {
        dispatch({
          type: SET_USER_EMAIL_NEWSLETTER_CODE,
          payload: { email: email, code: newCode },
        });
      }
      return response;
    } catch (error) {
      return error.response;
    }
  };
};

export const createUserNewsletter = (datas) => {
  return async () => {
    try {
      const response = await axiosInstance.post("create/newsletter", datas);
      return response;
    } catch (error) {
      return error.response;
    }
  };
};

export const setUserNewsletterPreferencesSaved = () => {
  return (dispatch) => {
    dispatch({ type: SET_USER_EMAIL_NEWSLETTER_PREFERENCES_UPDATED });
  };
};

export const removeNewsletterEmailCode = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USER_EMAIL_NEWSLETTER_CODE });
  };
};
